// bootstrap customize
@import "~bootstrap/scss/bootstrap";
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.25;
}

@media screen and (min-width: 768px) {
  .fs-lg-1 {
    font-size: 28px !important;
  }
}

@media screen and (max-width: 768px) {
  .bg-main {
    background-attachment: scroll !important;
  }
}

// own scss
@import "./scss/index.scss";
@import "./scss/header.scss";
