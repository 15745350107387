/* Start:: container */
@media screen and (min-width: 960px) {
  .container.history {
    max-width: 780px;
  }
}
/* End:: container */

/* Start:: thead */
.table thead tr {
  border-bottom: 2px solid #000;
}

.table thead tr td {
  font-weight: 200;
}

.table thead .num {
  width: 10%;
}

.table thead .author {
  width: 20%;
}

.table thead .title {
  width: 50%;
}

.table thead .date {
  width: 20%;
}
/* End:: thead */

/* Start:: tbody */
.table tbody tr:last-child {
  border-bottom: 2px solid #000;
}

tr th[scope="row"] {
  font-weight: 400 !important;
}
/* End:: tbody */
