// MainHeader
.pc-nav {
  display: flex;

  .pc-nav-item {
    padding: 0 20px;
    display: flex;
    align-items: center;

    .nav-link.active {
      color: #0d6efd;
    }
    .nav-link:hover {
      cursor: pointer !important;
    }
  }
}

.mobile-nav {
  display: none;
  z-index: 100;
}

.mobile-menu-list {
  height: 0;
  transition: all 1s;
  opacity: 0;
  .nav-link.active {
    color: #0d6efd;
  }
  .nav-link:hover {
    cursor: pointer !important;
  }
}

.mobile-menu-list.active {
  height: fit-content;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .pc-nav {
    display: none;
  }

  .mobile-nav {
    display: block;
  }
}
