// this is main_page //

// general
body {
  font-family: "Pretendard Variable", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

.img {
  max-width: 90%;
}

@media screen and (max-width: 768px) {
  .img {
    max-width: 100%;
  }
}

.blur {
  background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}
.blur-inverse {
  background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
.bg-blur {
  width: fit-content;
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 30px;
}
.img-blur {
  width: 100%;
  height: 100%;
  padding: 40px;
  background: rgba(255, 255, 255, 0.6);
}

.transition {
  transition: all 0.5s ease-in-out;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.moveUpDown {
  animation: moveUpDown 2s ease-in-out infinite;
}

// custom
.sec1 {
  .bg-main {
    background-image: url("../../public/img/main_image.jpg");
    background-attachment: fixed;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    background-position: center;
    .blur.blur_sec1 {
      width: 100%;
      height: 100%;
      .block {
        height: 40%;
      }
    }
    .animation-bounce {
      position: relative;
      width: 50px;
      height: 50px;
    }
  }
}
.bg-sec3 {
  background-image: url("../../public/img/sec3.png");
  background-attachment: fixed;
  width: 100vw;
  height: 150vh;
  .blur-inverse.blur_sec3 {
    width: 100%;
    height: 100%;
  }
}

.sec4 {
  .detail {
    display: none;
  }

  .portfolio-image {
    .flex-item {
      width: 560px;
      height: 380px;
      border-radius: 20px;
      margin: 20px;
      background-size: contain;
    }

    @media screen and (max-width: 768px) {
      .flex-item {
        background-size: cover;
      }
    }

    .bg-damyang {
      background-image: url("../../public/img/damyang.png");
    }
    .bg-gwangju {
      background-image: url("../../public/img/gwangju.png");
    }
    .bg-naju {
      background-image: url("../../public/img/naju.png");
    }
    .bg-suncheon {
      background-image: url("../../public/img/suncheon.png");
    }
  }

  .transition-damyang,
  .transition-gwangju,
  .transition-naju,
  .transition-suncheon {
    .img-blur {
      backdrop-filter: blur(5px);
    }
    .intro {
      font-size: 0px;
    }
    .title {
      font-size: 20px;
      transition: all 1s;
    }
    .detail {
      display: block;
      transition: all 1s;
    }
    .icon {
      opacity: 0;
    }
  }
}
